import React from 'react';

export default function PinComponent({ pin, setPin, inputType = "text" }) {

    function getKeyPress(e, element) {

        if (e.key === "Delete" || e.key === "Backspace") {
            if (element.value === "") {
                if (element.previousSibling) {
                    element.previousSibling.focus();
                }
            }
        }
    }

    const handleChangePin = (element, index) => {
        if (isNaN(element.value)) return false;

        setPin([...pin.map((d, idx) => (idx === index ? element.value : d))]);

        //Focus next input        
        if (element.value !== "") {
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    return (
        <div className="row d-flex mx-1 mx-md-3 justify-content-space-between">
            {pin?.map((data, index) => {
                return (
                    <input
                        className="text-center styled-input col form-control p-0 me-2 me-md-2 form-control-lg rounded-3"
                        type={inputType}
                        autoComplete="off"
                        inputMode="numeric"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={e => handleChangePin(e.target, index)}
                        onKeyDown={e => getKeyPress(e, e.target)}
                    />
                );
            })}
        </div>
    )
}
