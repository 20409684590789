import {Link} from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="py-5 footer shadow-sm">
                <div className="container pb-5">
                    <div className="row px-lg-5">
                        <div className="col-12 col-md-6 col-lg-3">
                            <img src="assets/logos/FuelCredit Logo.svg" alt="Logo" style={{marginTop: "-0.7rem", paddingBottom:"0.5rem"}} />
                            <ul className="list-inline campany-list">
                                <li className="mt-2 mb-3"><Link to="https://myfuelcredit.com/" target="_blank"  className="text-decoration-none general-text">For Individuals/Families</Link></li>
                                <li className="mt-2 mb-3"><Link to="https://corporate.myfuelcredit.com/" target="_blank"  className="text-decoration-none general-text">For Corporates</Link></li>
                                <li className="mt-2 mb-3"><Link to="/" className="text-decoration-none general-text">For Merchants</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6  col-lg-3">
                            <h2 className="column-heading">Company</h2>
                            <ul className="list-inline campany-list">
                                <li className="mt-2 mb-3"><Link to="/" className="text-decoration-none general-text">Careers</Link></li>
                                <li className="mt-2 mb-3"><Link to="/terms-of-service" className="text-decoration-none general-text">Terms of Use</Link></li>
                                <li className="mt-2 mb-3"><Link to="/privacy-policy" className="text-decoration-none general-text">Privacy Policy</Link></li>
                            </ul>
                        </div><div className="col-12 col-md-6 col-lg-3">
                            <h2 className="column-heading">Support</h2>
                            <ul className="list-inline">
                                <li className="mt-2 mb-3"><Link to="/" className="text-decoration-none general-text">Blog</Link></li>
                                <li className="mt-2 mb-3"><Link to="/" className="text-decoration-none general-text">Customer Support</Link></li>
                            </ul>
                        </div><div className="col-12 col-md-6 col-lg-3">
                            <h2 className="column-heading">Contact</h2>
                            <ul className="list-inline">
                                <li><a href="mailto:support@myfuelcredit.com" target="_blank" rel="noopener noreferrer" className="text-decoration-none general-text text-success">support@myfuelcredit.com</a></li>
                                <li className="mt-2 mb-3 general-text">2nd floor, Ereke House, 15, CIPM Avenue, Central Business District, Alausa, Ikeja, Lagos.</li>
                                <div className="d-inline-block">
                                    <a href="https://www.instagram.com/myfuelcredit/" className="me-2" target="_blank" rel="noopener noreferrer"><img src="assets/icons/Instagram.svg" alt="instagram" /></a>
                                    <a href="https://www.facebook.com/Myfuelcredit-100400382514427" className="me-2" target="_blank" rel="noopener noreferrer"><img src="assets/icons/Facebook.svg" alt="facebook" /></a>
                                    <Link to="https://www.linkedin.com/company/fuelcredit-ltd/" className="me-2"><img src="assets/icons/linkedin.svg" alt="twitter" style={{height:"25px", width:"25px"}}/></Link>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;