import { configureStore } from "@reduxjs/toolkit";

import { apiSliceAdmin } from "./api/admin/apiSliceAdmin";
import { apiSliceAttendant } from "./api/attendant/apiSliceAttendant";

import authReducerAdmin from "../features/admin/auth/authSliceAdmin";
import authReducerAttendant from "../features/attendant/auth/authSliceAttendant";



export const store = configureStore({
    reducer: {
        [apiSliceAdmin.reducerPath]: apiSliceAdmin.reducer,
        [apiSliceAttendant.reducerPath]: apiSliceAttendant.reducer,

        authAdmin: authReducerAdmin,
        authAttendant: authReducerAttendant,

    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            apiSliceAdmin.middleware,
            apiSliceAttendant.middleware
        ]),
    devTools: true
})