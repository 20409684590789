import React from "react";
import { Outlet, useLocation } from "react-router-dom"
import { useSelector } from "react-redux";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import Footer from "./Footer";
import GuestButton from "../shared/GuestButton";


function Header() {
    const location = useLocation();


    return (
        <React.Fragment>
            <Navbar collapseOnSelect className="py-3 shadow-sm" sticky="top" variant="light" expand="lg">
                <Container className="align-items-lg-center">
                    <Link className="mb-3 navbar-brand" to="/"><img className="align-text-top" src="assets/logos/FuelCredit Logo.svg" alt="fuelcredit" /></Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse className="" id="responsive-navbar-nav">
                        <Nav className="flex-grow-1 justify-content-between">
                            <div className="d-flex flex-column align-items-start flex-lg-row align-items-lg-center">
                                <Nav.Link
                                    href="https://myfuelcredit.com/"
                                    to="https://myfuelcredit.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Individuals/Corporates
                                </Nav.Link>
                                <Nav.Link 
                                    as={Link} to="/" 
                                    href="/" 
                                    className={"active"}>Merchants</Nav.Link>
                            </div>
                            <div className="d-flex flex-column align-items-start flex-lg-row align-items-lg-center">
                                <Nav.Link as={Link} to="/aboutus" href="/aboutus" className={`${location.pathname === "/aboutus" ? "active" : ""}`}>About Us</Nav.Link>
                                <Nav.Link as={Link} to="/faq" href="/faq" className={`${location.pathname === "/faq" ? "active" : ""}`}>FAQs</Nav.Link>
                            </div>
                        </Nav>
                        <GuestButton />
                    </Navbar.Collapse>
                </Container>
            </Navbar>


            <Outlet />

            <Footer />
        </React.Fragment>

    )
}

export default Header