import { apiSliceAttendant } from "../../../app/api/attendant/apiSliceAttendant";


export const authApiSliceAttendant = apiSliceAttendant.injectEndpoints({
  endpoints(build) {
    return {
      loginAttendant: build.mutation({
        query: (credentials) => ({
          url: `/api/merchant/login`,
          method: "POST",
          data: { ...credentials },
          params: ""
        })
      }),
      logoutAttendant: build.mutation({
        query: () => ({
          url: `/api/merchant/logout`,
          method: "POST",
          params: ""
        }),
      }),
      validateUserAttendant: build.query({
        query: () => ({
          url: `/api/merchant`,
          method: "GET",
          params: ""
        })
      }),
      registerAttendant: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/register`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
      postForgotPasswordAttendant: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/password/request`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
      postResetPasswordAttendant: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/password/reset`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
      
      postUpdatePassword: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/profile/change-password`,
          method: "POST",
          data: { ...payload, _method: "PUT" }
        })
    }),
    }
  }
})



export const {
  useLoginAttendantMutation,
  useLogoutAttendantMutation,
  useValidateUserAttendantQuery,
  useRegisterAttendantMutation,
  usePostForgotPasswordAttendantMutation,
  usePostResetPasswordAttendantMutation,
} = authApiSliceAttendant;