import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PinComponent from '../shared/PinComponent';

const VerifyPhoneNumber = () => {

    const [phoneNumber, setPhoneNumber] = useState();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [statusMessage, setStatusMessage] = useState("");
    const [statusError, setStatusError] = useState("");
    const [disableResendOTP, setDisableResendOTP] = useState(false);
    const [disableEditPhone, setDisableEditPhone] = useState(false);
    const [showCheckingOTP, setShowCheckingOTP] = useState(false);

    let navigate = useNavigate();   

    const resendOTP = (e) => {
        e.preventDefault()
        const data = {
            phone: phoneNumber,
        }
        setStatusMessage("")
        setStatusError("")
        setDisableResendOTP(true);
        setDisableEditPhone(true);
        // apiClient.post('/api/v1/resend-otp', data)
        //     .then(res => {
        //         setStatusMessage(res.data.message);
        //         setTimeout(() => {
        //             setDisableResendOTP(false);
        //             setDisableEditPhone(false);
        //             setStatusMessage("");
        //         }, 1000);
        //     })
        //     .catch(err => {
        //         console.log(err)
        //         if (err.response?.status === 401) {
        //             setStatusError(err.response.data.message)
        //             setDisableResendOTP(false);
        //             setDisableEditPhone(false);
        //         }
        //         if (err.response?.status === 422) {
        //             setStatusError(err.response.data.message)
        //             setDisableResendOTP(false);
        //             setDisableEditPhone(false);
        //         }
        //     })

    }

    useEffect(() => {
        setPhoneNumber(localStorage.getItem("phone"));

        const sendOTP = () => {

            if (otp.length === 6) {
                const data = {
                    phone: phoneNumber,
                    code: otp.join(''),
                }

                setDisableResendOTP(true);
                setDisableEditPhone(true);
                setShowCheckingOTP(true);

                // apiClient.post('/api/v1/verify-otp', data)
                //     .then(res => {
                //         setShowCheckingOTP(false);
                //         setStatusMessage(res.data.message);
                //         localStorage.removeItem('phone');
                //         setTimeout(() => {
                //             setStatusMessage("");
                //             navigate("/login-verified");
                //         }, 3000);
                //     })
                //     .catch(err => {
                //         console.log(err)
                //         if (err.response.status === 401) {
                //             setStatusError(err.response.data.message)
                //             setDisableResendOTP(false);
                //             setDisableEditPhone(false);
                //             setShowCheckingOTP(false);
                //         }
                //         if (err.response.status === 422) {
                //             setStatusError(err.response.data.message)
                //             setDisableResendOTP(false);
                //             setDisableEditPhone(false);
                //             setShowCheckingOTP(false);
                //         }
                //     })
            }
        }

        if (otp.some((element) => element !== "")) {
            setStatusMessage("");
            setStatusError("");
        }

        if (otp.every((element) => element !== "")) {
            sendOTP()
        }

    }, [otp, navigate, phoneNumber])

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="mx-auto col-12 col-md-10 col-lg-7 verify-phone-number-box ">                        

                        <div className="pt-5 verify-phone-number-inner-box">
                            <div className="verify-phone-number-title justify-content-center">
                                <svg className="mb-3" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="34.5" cy="34.5" r="34.5" fill="#DCF0D1" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28.5 19.5H39C41.2061 19.5 42.5589 19.5064 43.538 19.638C44.3985 19.7537 44.5422 19.9183 44.5592 19.9378L44.5607 19.9393L44.5622 19.9408C44.5818 19.9578 44.7463 20.1015 44.862 20.962C44.9936 21.9411 45 23.2939 45 25.5V42C45 44.2061 44.9936 45.5589 44.862 46.538C44.7463 47.3985 44.5818 47.5422 44.5622 47.5592L44.5607 47.5607L44.5592 47.5622C44.5422 47.5818 44.3985 47.7463 43.538 47.862C42.5589 47.9936 41.2061 48 39 48H28.5C26.2939 48 24.9411 47.9936 23.962 47.862C23.1015 47.7463 22.9578 47.5818 22.9408 47.5622L22.9393 47.5607L22.9378 47.5592C22.9183 47.5422 22.7537 47.3985 22.638 46.538C22.5064 45.5589 22.5 44.2061 22.5 42V25.5C22.5 23.2939 22.5064 21.9411 22.638 20.962C22.7537 20.1015 22.9183 19.9578 22.9378 19.9408L22.9393 19.9393L22.9408 19.9378C22.9578 19.9183 23.1015 19.7537 23.962 19.638C24.9411 19.5064 26.2939 19.5 28.5 19.5ZM19.5 25.5C19.5 21.2574 19.5 19.136 20.818 17.818C22.136 16.5 24.2574 16.5 28.5 16.5H39C43.2426 16.5 45.364 16.5 46.682 17.818C48 19.136 48 21.2574 48 25.5V42C48 46.2426 48 48.364 46.682 49.682C45.364 51 43.2426 51 39 51H28.5C24.2574 51 22.136 51 20.818 49.682C19.5 48.364 19.5 46.2426 19.5 42V25.5ZM39 40.5H28.5V43.5H39V40.5Z" fill="#4FB518" />
                                </svg>

                                <h3 className="text-center dashboard-header-text mb-3">Verify your phone number</h3>
                            </div>
                            <p className="mt-2 text-center general-text">
                                A 6-digit One Time Password(OTP) has been sent to <span className="fw-bold">{phoneNumber}.</span> Enter the OTP below to complete your account registration
                            </p>
                            <form className="mt-5" id="otpForm" autoComplete="off">
                                {statusMessage ?
                                    <p className="text-capitalize general-text text-primary text-center mt-3">{statusMessage}</p>
                                    :
                                    <div></div>}
                                {showCheckingOTP ?
                                    <p className={`general-text text-dark text-center mt-3 ${showCheckingOTP ? "" : "d-none"}`}>Verifying your phone number</p>
                                    :
                                    <div></div>}

                                <PinComponent pin={otp} setPin={setOtp}/>

                                {statusError ?
                                    <p className="styled-form-error text-capitalize general-text text-danger text-center mt-3">{statusError}</p>
                                    :
                                    <div></div>}
                            </form>
                            <div className="mt-5 mb-3 d-flex justify-content-between">
                                <span id="editPhoneNumber" style={{cursor:"pointer"}} className={`px-2 me-0 general-text text-decoration-none text-primary ${disableEditPhone ? "text-muted" : "text-success"}`} onClick={() => disableEditPhone ? null : navigate("/edit-phone") }>Edit phone number</span>
                                <a href="#/" disabled={disableResendOTP} id="resendOTP" className={`px-2 me-0 general-text text-decoration-none ${disableResendOTP ? "text-muted" : "text-primary"}`} onClick={resendOTP}>Resend OTP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}


export default VerifyPhoneNumber;
