import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axiosClientAttendant from "./axiosClientAttendant";


const axiosBaseQuery =({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {

    try {
      const result = await axiosClientAttendant({
        url: baseUrl + url, 
        method, 
        data,
        params
      })
      
      return { data: result.data };

    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }



export const apiSliceAttendant = createApi({
  reducerPath: "attendant",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({})
})

