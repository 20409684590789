import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useValidateUserAdminQuery } from "../../redux/features/admin/auth/authApiSliceAdmin";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../redux/features/admin/auth/authSliceAdmin";


function RedirectIfNotAuthAdmin({ url }) {
    const location = useLocation();
    const currentUser = useSelector(selectCurrentAdmin);
    const redirectUrl = url ?? "/merchant/dashboard";

    const { error } = useValidateUserAdminQuery();

    if(error == null && currentUser != null) {
        return <Navigate to={redirectUrl} state={{from: location}} replace/>
    }

    return <Outlet />;

}

export default RedirectIfNotAuthAdmin;