// @ts-nocheck

import { createSlice } from "@reduxjs/toolkit";

let refetchUser = JSON.parse(localStorage.getItem("FC_ATTENDANT")) || null;

const authSliceAttendant = createSlice({
  name: "authAttendant",
  initialState: { user: refetchUser },
  reducers: {
    setCredentialsAttendant: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem("FC_ATTENDANT", JSON.stringify(state.user));
      localStorage.setItem("FC_ATTENDANT_TOKEN", JSON.stringify(action.payload.token));
    },
    deleteCredentialsAttendant: (state, action) => {
      state.user = null;
      localStorage.removeItem("FC_ATTENDANT");
      localStorage.removeItem("FC_ATTENDANT_TOKEN");
    },
    updateCredentialsAttendant: (state, action) => {
      let newState = { ...state.user, ...action.payload };
      state.user = newState;
      localStorage.setItem("FC_ATTENDANT", JSON.stringify(newState));
    }
  },
})


export const {
  setCredentialsAttendant,
  deleteCredentialsAttendant,
  updateCredentialsAttendant
} = authSliceAttendant.actions;

export default authSliceAttendant.reducer;

export const selectCurrentAttendant = (state) => state.authAttendant.user;
