import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const axiosClientAdmin = axios.create({
    baseURL: `${BASE_URL}`,
    // withCredentials: true,
    xsrfHeaderName: "X-XSRF-TOKEN",
    responseType: "json",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});


axiosClientAdmin.interceptors.request.use( 
    (config) => {
        const token = JSON.parse(localStorage.getItem("FC_MERCHANT_TOKEN"));
        config.headers.Authorization = token ? `Bearer ${token}` : ""
        
        return config;
    },
    (error) => {
        console.log("err axios : ", error);
    }
);



export default axiosClientAdmin;