import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

export default function GuestButton() {



    return (
        <div className="d-inline-flex flex-column flex-lg-row gap-lg-4">
            <Nav.Link as={Link} to="/account-type" href="/account-type" className="ps-0 py-2">
                <button className="btn btn-outline-primary m-0 ms-lg-4" style={{ whiteSpace: "nowrap" }}>
                    Login 
                </button>
            </Nav.Link>
            <Nav.Link as={Link} to="/merchant/register" href="/merchant/register" className="ps-0 py-2">
                <button className="btn btn-primary btn-general me-0">
                    Create free account
                </button>
            </Nav.Link>
        </div>
    )
}
