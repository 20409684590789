import { createSlice } from "@reduxjs/toolkit";

let refetchUser = JSON.parse(localStorage.getItem("FC_MERCHANT")) || null;

const authSliceAdmin = createSlice({
  name: "authAdmin",
  initialState: { user: refetchUser },
  reducers: {
    setCredentialsAdmin: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem("FC_MERCHANT_TOKEN", JSON.stringify(action.payload.token));
      localStorage.setItem("FC_MERCHANT", JSON.stringify(action.payload.user));
    },
    deleteCredentialsAdmin: (state, action) => {
      localStorage.removeItem("FC_MERCHANT_TOKEN");
      localStorage.removeItem("FC_MERCHANT");
      state.user = null;
    },
    updateCredentialsAdmin: (state, action) => {
      let newState = { ...state.user, ...action.payload };
      state.user = newState;
      localStorage.setItem("FC_MERCHANT", JSON.stringify(newState));

    }
  },
})


export const {
  setCredentialsAdmin,
  deleteCredentialsAdmin,
  updateCredentialsAdmin
} = authSliceAdmin.actions;

export default authSliceAdmin.reducer;

export const selectCurrentAdmin = (state) => state.authAdmin.user;
