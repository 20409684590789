import { createApi } from "@reduxjs/toolkit/query/react";
import axiosClientAdmin from "./axiosClientAdmin";


const axiosBaseQuery = ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {

    try {
      const result = await axiosClientAdmin({
        url: baseUrl + url, 
        method, 
        data,
        params
      })
      
      return { data: result.data };

    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }



export const apiSliceAdmin = createApi({
  reducerPath: "admin",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({}),
})

