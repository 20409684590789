import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAdmin, updateCredentialsAdmin } from "../../redux/features/admin/auth/authSliceAdmin";
import { useValidateUserAdminQuery } from "../../redux/features/admin/auth/authApiSliceAdmin";

function RequireAuthAdmin({ url }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentAdmin);
    const redirectUrl = url ?? "/merchant/login/admin";
    
    const { data, error } = useValidateUserAdminQuery(undefined, { refetchOnMountOrArgChange: true });

    useEffect(()=> {
        if (data) {
            let newUser = { ...currentUser, ...data?.data };
            dispatch(updateCredentialsAdmin(newUser));
        }
        return () => {
            console.log("clean up");
        };
    }, [data, dispatch]);


    if(!currentUser || error != null){
        return <Navigate to={redirectUrl} state={{from: location}} replace/>
    }

    return <Outlet />;
    
}

export default RequireAuthAdmin;