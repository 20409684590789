import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useValidateUserAttendantQuery } from "../../redux/features/attendant/auth/authApiSliceAttendant";
import { useSelector } from "react-redux";
import { selectCurrentAttendant } from "../../redux/features/attendant/auth/authSliceAttendant";

function RedirectIfNotAuthAttendant({ url }) {
    const location = useLocation();
    const currentUser = useSelector(selectCurrentAttendant);
    const redirectUrl = url ?? "/attendant/dashboard";

    const { error } = useValidateUserAttendantQuery();
    

    if(error == null && currentUser != null) {
        return <Navigate to={redirectUrl} state={{from: location}} replace/>
    }

    return <Outlet />;

}

export default RedirectIfNotAuthAttendant;