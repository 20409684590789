import { apiSliceAdmin } from "../../../app/api/admin/apiSliceAdmin";


export const authApiSliceAdmin = apiSliceAdmin.injectEndpoints({
  endpoints(build) {
    return {
      loginAdmin: build.mutation({
        query: (credentials) => ({
          url: `/api/merchant/login`,
          method: "POST",
          data: { ...credentials },
        }),
      }),
      logoutAdmin: build.mutation({
        query: () => ({
          url: `/api/merchant/logout`,
          method: "POST",
        }),
      }),
      validateUserAdmin: build.query({
        query: () => ({
          url: `/api/merchant`,
          method: "GET",
        }),
      }),
      postApplyAsMerchant: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/apply`,
          method: "POST",
          data: { ...payload },
        }),
      }),
      postForgotPasswordAdmin: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/password/request`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
      postResetPasswordAdmin: build.mutation({
        query: ({ payload }) => ({
          url: `/api/merchant/password/reset`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
    }
  }
})



export const {
  useLoginAdminMutation,
  useLogoutAdminMutation,
  useValidateUserAdminQuery,
  usePostApplyAsMerchantMutation,
  usePostForgotPasswordAdminMutation,
  usePostResetPasswordAdminMutation,
} = authApiSliceAdmin;