import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useValidateUserAttendantQuery } from "../../redux/features/attendant/auth/authApiSliceAttendant";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAttendant, updateCredentialsAttendant } from "../../redux/features/attendant/auth/authSliceAttendant";


function RequireAuthAttendant({ url }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentAttendant);
    const redirectUrl = url ?? "/merchant/login/attendant";

    const { data, error } = useValidateUserAttendantQuery(
        undefined,
        { refetchOnMountOrArgChange: true }
    );

    useEffect(()=> {
        if (data) {
            let newUser = { ...currentUser, ...data?.data};
            dispatch(updateCredentialsAttendant(newUser));
        }
    }, [data, dispatch]);

    if(!currentUser || error != null){
        return <Navigate to={redirectUrl} state={{from: location}} replace/>
    }

    return <Outlet />;

}

export default RequireAuthAttendant;