import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./redux/app/store";
import "./App.scss";


import VerifyPhoneNumber from "./components/VerifyPhoneNumber";
import Fallback from "./components/Fallback";
import NotFound404 from "./components/NotFound404";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";

import RequireAuthAttendant from "./components/RequireAuth/RequireAuthAttendant";
import RequireAuthAdmin from "./components/RequireAuth/RequireAuthAdmin";
import RedirectIfNotAuthAttendant from "./components/RedirectIfNotAuth/RedirectIfNotAuthAttendant";
import RedirectIfNotAuthAdmin from "./components/RedirectIfNotAuth/RedirectIfNotAuthAdmin";
import AttendantLoginForm from "./pages/merchant/MerchantLoginForm/AttendantLoginForm";

const CustomerSupportPage = React.lazy(() => import("./pages/CustomerSupportPage"));
const AboutUsPage = React.lazy(() => import("./components/AboutUs"));
const FAQPage = React.lazy(() => import("./components/FAQs"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy.jsx"));
const TermsOfService = React.lazy(() => import("./components/TermsOfService.jsx"));

// Pages for merchants
const MerchantLandingPage = React.lazy(() => import("./pages/merchant/landingPage/MerchnantLandingPage"));
const MerchantLoginForm = React.lazy(() => import("./pages/merchant/MerchantLoginForm/MerchantLoginForm"));
const MerchantDashboardHome = React.lazy(() => import("./pages/merchant/MerchantDashboardHome"));
const MerchantDashboardSales = React.lazy(() => import("./pages/merchant/MerchantDashboardSales"));
const MerchantDashboardWallet = React.lazy(() => import("./pages/merchant/MerchantDashboardWallet"));
const MerchantDashboardAttendants = React.lazy(() => import("./pages/merchant/MerchantDashboardAttendants/MerchantDashboardAttendants"));
const MerchantDashboardProfile = React.lazy(() => import("./pages/merchant/MerchantProfile"));
const FillingStations = React.lazy(() => import("./pages/merchant/FillingStations/FillingStations"))
const MerchantDashboardAddFillingStation = React.lazy(() => import("./components/merchant/MerchantDashboardAddFillingStation"))
const MerchantAttendantFundingRecords = React.lazy(() => import("./pages/merchantAttendant/MerchantAttendantFundingRecords"));

// Components for merchants
const MerchantDashboardApp = React.lazy(() => import("./components/merchant/MerchantDashboardApp"));
const MerchantRegisterFormPage = React.lazy(() => import("./components/merchant/MerchantRegisterFormPage"));
const ForgotPasswordPhoneAdmin = React.lazy(() => import("./components/merchant/ForgotPasswordPhoneAdmin"));
const MerchantAwaitingApproval = React.lazy(() => import("./components/merchant/MerchantAwaitingApproval"));
const MerchantDashboardSingleSale = React.lazy(() => import("./components/merchant/MerchantDashboardSingleSale"));
const MerchantDashboardWalletTransaction = React.lazy(() => import("./components/merchant/MerchantDashboardWalletTransaction"));
const MerchantDashboardAddAttendant = React.lazy(() => import("./components/merchant/MerchantDashboardAddAttendant"));
const MerchantDashboardAttendant = React.lazy(() => import("./components/merchant/MerchantDashboardAttendant/MerchantDashboardAttendant"));
const MerchantDashboardEditProfile = React.lazy(() => import("./components/merchant/MerchantAdminEditProfile"));
const MerchantAttendantApp = React.lazy(() => import("./components/merchantAttendant/MerchantAttendantApp"));
const MerchantAttendantHome = React.lazy(() => import("./pages/merchantAttendant/MerchantAttendantHome/MerchantAttendantHome"));
const MerchantAttendantSalesRecords = React.lazy(() => import("./pages/merchantAttendant/MerchantAttendantSalesRecords"));
const MerchantAttendantFundWallet = React.lazy(() => import("./pages/merchantAttendant/MerchantAttendantFundWallet"));
const MerchantDashboardEditAttendant = React.lazy(() => import("./components/merchant/MerchantDashboardEditAttendant"));
const ViewFillingStation = React.lazy(() => import("./components/merchant/ViewFillingStation/ViewFillingStation"));

// Generic components
const VerificationSuccessfulLogin = React.lazy(() => import("./components/VerificationSuccessfulLogin"));
const AccountType = React.lazy(() => import("./components/AccountType"));
const ForgotPasswordPhoneAttendant = React.lazy(() => import("./components/merchantAttendant/ForgotPaswordPhoneAttendant"));
const ForgotPasswordEmail = React.lazy(() => import("./components/ForgotPasswordEmail"));
const EditPhoneNumber = React.lazy(() => import("./components/EditPhoneNumber"));


const App = () => {

  return (
    <Provider store={store}>
      <>
        <Router>
          <ScrollToTop />
          <div>
            <Routes>
             
              <Route path="/" element={<Header />}>
                 {/* Guest Routes */}
                <Route index element={
                  <React.Suspense fallback={<Fallback />}>
                    <MerchantLandingPage />
                  </React.Suspense>
                } />
                <Route path="*" element={<NotFound404 />} />

                <Route path="privacy-policy" element={
                  <React.Suspense fallback={<Fallback />}>
                    <PrivacyPolicy />
                  </React.Suspense>
                }></Route>
                <Route path="terms-of-service" element={
                  <React.Suspense fallback={<Fallback />}>
                    <TermsOfService />
                  </React.Suspense>
                }></Route>
                <Route path="merchant" element={
                  <React.Suspense fallback={<Fallback />}>
                    <MerchantLandingPage />
                  </React.Suspense>
                }></Route>
                <Route path="aboutus" element={
                  <React.Suspense fallback={<Fallback />}>
                    <AboutUsPage />
                  </React.Suspense>
                }></Route>
                <Route path="faq" element={
                  <React.Suspense fallback={<Fallback />}>
                    <FAQPage />
                  </React.Suspense>
                }></Route>
                <Route path="merchant/register" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantRegisterFormPage />
                    </React.Suspense>
                  }></Route>
                  <Route path="merchant/forgot-password/admin" element={
                    <React.Suspense fallback={<Fallback />}>
                      <ForgotPasswordPhoneAdmin />
                    </React.Suspense>
                  }></Route>
                <Route path="merchant/awaiting-approval" element={
                  <React.Suspense fallback={<Fallback />}>
                    <MerchantAwaitingApproval />
                  </React.Suspense>
                }></Route>
                <Route path="account-type" element={
                  <React.Suspense fallback={<Fallback />}>
                    <AccountType />
                  </React.Suspense>
                }></Route>
                <Route path="verify-phone-number" element={
                  <VerifyPhoneNumber />
                }></Route>
                <Route path="edit-phone" element={
                  <React.Suspense fallback={<Fallback />}>
                    <EditPhoneNumber />
                  </React.Suspense>
                }></Route>
                
                <Route path="login-verified" element={
                  <React.Suspense fallback={<Fallback />}>
                    <VerificationSuccessfulLogin />
                  </React.Suspense>
                }></Route>
                <Route path="/merchant/forgot-password/attendant" element={
                  <React.Suspense fallback={<Fallback />}>
                    <ForgotPasswordPhoneAttendant />
                  </React.Suspense>
                }></Route>
                <Route path="/forgot-password-email" element={
                  <React.Suspense fallback={<Fallback />}>
                    <ForgotPasswordEmail />
                  </React.Suspense>
                }></Route>

                <Route element={<RedirectIfNotAuthAdmin />}>
                  <Route path="merchant/login/admin" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantLoginForm />
                    </React.Suspense>
                  }></Route>
                </Route>

                <Route element={<RedirectIfNotAuthAttendant />}>
                  <Route path="merchant/login/attendant" element={
                    <React.Suspense fallback={<Fallback />}>
                      <AttendantLoginForm />
                    </React.Suspense>
                  }></Route>
                </Route>
                </Route>

              {/* Require auth merchant admin */}
              <Route element={<RequireAuthAdmin url="/merchant/login/admin" />}>
                <Route path="/merchant/dashboard" element={
                  <React.Suspense fallback={<Fallback />}>
                    <MerchantDashboardApp />
                  </React.Suspense>
                }>
                  <Route index element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardHome />
                    </React.Suspense>
                  } />
                  <Route path="sales" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardSales />
                    </React.Suspense>
                  }></Route>
                  <Route path="sales/:saleId" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardSingleSale />
                    </React.Suspense>
                  }></Route>
                  <Route path="wallet" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardWallet />
                    </React.Suspense>
                  }></Route>
                  <Route path="wallet/:walletId" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardWalletTransaction />
                    </React.Suspense>
                  }></Route>
                  <Route path="attendants" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardAttendants />
                    </React.Suspense>
                  }></Route>
                  <Route path="attendants/add" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardAddAttendant />
                    </React.Suspense>
                  }></Route>
                  <Route path="attendants/:attendantName/:id" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardAttendant />
                    </React.Suspense>
                  }></Route>
                  <Route path="attendants/:attendantName/:id/edit" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardEditAttendant />
                    </React.Suspense>
                  }></Route>
                  <Route path="fillingstations" element={
                    <React.Suspense fallback={<Fallback />}>
                      <FillingStations />
                    </React.Suspense>
                  }></Route>
                  <Route path="fillingstations/add" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardAddFillingStation />
                    </React.Suspense>
                  }></Route>
                  <Route path="fillingstations/:id" element={
                    <React.Suspense fallback={<Fallback />}>
                      <ViewFillingStation />
                    </React.Suspense>
                  }></Route>
                  <Route path="profile" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardProfile />
                    </React.Suspense>
                  }></Route>
                  <Route path="profile/edit" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantDashboardEditProfile />
                    </React.Suspense>
                  }></Route>
                  <Route path="customer-support" element={
                    <React.Suspense fallback={<Fallback />}>
                      <CustomerSupportPage />
                    </React.Suspense>
                  }></Route>
                </Route>
              </Route>

              {/* Require auth merchant attendant*/}
              <Route element={<RequireAuthAttendant url="/merchant/login/attendant" />}>
                <Route path="/attendant/dashboard" element={
                  <React.Suspense fallback={<Fallback />}>
                    <MerchantAttendantApp />
                  </React.Suspense>
                }>
                  <Route index element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantAttendantHome />
                    </React.Suspense>
                  } />
                  <Route path="sales" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantAttendantSalesRecords />
                    </React.Suspense>
                  }></Route>
                  <Route path="wallet" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantAttendantFundWallet />
                    </React.Suspense>
                  }></Route>
                  <Route path="wallet-funding-records" element={
                    <React.Suspense fallback={<Fallback />}>
                      <MerchantAttendantFundingRecords />
                    </React.Suspense>
                  }></Route>
                  <Route path="customer-support" element={
                    <React.Suspense fallback={<Fallback />}>
                      <CustomerSupportPage />
                    </React.Suspense>
                  }></Route>
                </Route>
              </Route>

            </Routes>
          </div>
        </Router>
      </>

    </Provider>
  );



}

export default App;
