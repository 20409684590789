import { useDispatch } from "react-redux";

import { useLoginAttendantMutation } from "../redux/features/attendant/auth/authApiSliceAttendant";
import { setCredentialsAttendant } from "../redux/features/attendant/auth/authSliceAttendant";
import { useNavigate } from "react-router-dom";


const useLoginAttendant = () => {

    const [loginAttendant] = useLoginAttendantMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    const handleLoginAttendant = async (data, setSubmitDisabled, setLoginError) => {
        
        setSubmitDisabled(true);
        setLoginError("");
        const { phone_number, password } = data ?? {};

        try {
            const userData = await loginAttendant({ 
              username: phone_number, 
              password 
            }).unwrap()
            
            setSubmitDisabled(false);
            dispatch(setCredentialsAttendant({ ...userData }));
            navigate("/attendant/dashboard");
        } catch (err) {

          if (!err?.status) {
            let errText = err?.data ?? "Login Failed, try again";
            setSubmitDisabled(false);
            setLoginError(errText);
            throw errText;
          }

          let errText = err?.data?.message ?? "Login Failed, try again";
          setSubmitDisabled(false);
          setLoginError(errText);
          throw errText;
        }
      };

      return handleLoginAttendant;

    
}

export default useLoginAttendant;